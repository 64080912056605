import CommonSetting from './common-setting.yaml'

export default {
  data () {
    return {
      commonSetting:CommonSetting,
    }
  },
  methods: {
    
    $_objectListSearch(items,keyval,keyname="title",val="detail") {
      for(const item  of items ){
        if( item[keyname] == keyval){
          return item[val]
        }
      }
      return "";
    },
    // key:router名 :{ title: 表示名, path: 表示パス}
    $_menu: function (menues){
      const menuList = {};
      for(const menu of menues){
        const routeObj = routerObj( menu, this.$router.options.routes);
        menuList[menu] = {
          title:routeObj.meta.title,
          path:routeObj.path,
          miniTitle:routeObj.meta.miniTitle,
        };
      }
      return menuList;
    },
    // 現在ページのタイトル取得
    $_documentTitle(){
      return (window.innerWidth <= 960) ? this.$route.meta.miniTitle : this.$route.meta.title;
    },
    $_getPageDesc(){
      return this.$route.meta.desc;
    },
    $_apiPost: function(data, url, func){
      this.axios.post(process.env.VUE_APP_API_URL + url, JSON.stringify(data)
          ,{ headers: { 'Content-Type': 'application/json'} }
          )
          .then(func)
          .catch((e) => { console.log(e); });    
    },
    $_apiGet: function(url,func){
      this.axios.get(process.env.VUE_APP_API_URL + url
          // , { headers: {"Authorization": this.commonSetting.mixyardConf} }
          )
          .then(func)
          .catch((e) => { console.log(e); });    
    },
    $_formatDate: function(date, format) {
      format = format.replace(/yyyy/g, date.getFullYear());
      format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
      format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
      format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
      format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
      format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
      format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
      return format;
    },
  }
}


// ルータオブジェクトから単一のルート情報取得
function routerObj(pageName,routeres) {
  const pages = routeres;
  for(const page of pages){
    if(page.name === pageName) return page;
  }
  return "";
}