<template>
  <div>
    <footer class="uk-light uk-padding-small" >
      
    </footer>
  </div>
</template>


<script>

export default {
  components: {
  },
}
</script>



<style scoped lang="scss">
footer {
  background-color: var(--main-color);
  position: sticky;/*固定する*/
  position: -webkit-sticky;/*Safari用ベンダープレフィックス*/
  bottom: 0;
  z-index: 80;
  font-weight: 600;
}

.company-name{
  font-size: 1.2rem;
}
table td{
}

</style>
