import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { 
    path: '/',
    name: 'home', // 名前付きルート用
    meta: { 
      title: 'HOME', 
      desc:''
    },
    component: () => import(/* webpackChunkName: "home" */ '../components/pages/Home.vue')
  },
  //##【tool】################################################################################################################
  { 
    path: '/service/tool',
    name: 'tool',
    meta: { 
      title: 'ツール ', 
      miniTitle: 'ツール', 
      desc:'いろんなツールを作成します。'
    },
    component: () => import(/* webpackChunkName: "tool" */ '../components/pages/tool/ToolTop')
  },
  { 
    path: '/service/tool/json-conv-format',  
    name: 'json-format',
    meta: { 
      title: 'JSON整形 きれいに加工 フォーマットツール ', 
      miniTitle: 'JSON整形', 
      desc:'１行になってしまったJSONや、ネストや改行がバラバラのJSONを見やすく整形します。あいまい整形を使えば、一部足りない場合でも見やすくすることができます。'
    },
    component: () => import(/* webpackChunkName: "tool" */ '../components/pages/tool/JsonFormat.vue')
  },
  { 
    path: '/service/tool/conv-json-yaml',
    name: 'conv-json-yaml',
    meta: { 
      title: 'json yaml 相互変換 きれいに加工 コンバートツール', 
      miniTitle: 'json/yaml', 
      desc:'jsonとyamlを相互変換します。コンバートツールです。'
    },
    component: () => import(/* webpackChunkName: "tool" */ '../components/pages/tool/ConvJsonYaml.vue')
  },
  { 
    path: '/service/tool/zipcode-en',
    name: 'zipcode-en',
    meta: { 
      title: '郵便番号で英語表記の住所検索', 
      miniTitle: '英語住所検索', 
      desc:'郵便番号から英語表記の住所を検索する。英語住所を郵便番号検索できるツール。'
    },
    component: () => import(/* webpackChunkName: "tool" */ '../components/pages/tool/ZipCodeEn.vue')
  },
  { 
    path: '/service/tool/dog-kcal',
    name: 'dog-kcal',
    meta: { 
      title: '愛犬の必要カロリー計算 わんこのごはん', 
      miniTitle: '犬の必要カロリー', 
      desc:'愛犬に必要なカロリーを確認できます。体重、活動量からDER(Daily Energy Requirement)を計算。'
    },
    component: () => import(/* webpackChunkName: "tool" */ '../components/pages/tool/DogKcal.vue')
  },
  { 
    path: '/service/tool/seven-eleven-items',
    name: 'seven-eleven-items',
    meta: { 
      title: 'セブンイレブンでコンビニダイエット！カロリー', 
      miniTitle: 'セブン カロリー', 
      desc:'セブンイレブン（SEVEN－ELEVEN）商品の成分表示 弁当やおかず、ホットスナック等 カロリー順、糖質順、タンパク質順、塩分順、食物繊維順、並び替え検索可能。'
    },
    component: () => import(/* webpackChunkName: "tool" */ '../components/pages/tool/SevenElevenItems.vue')
  },
  { 
    path: '/service/tool/natural-lawson-items',
    name: 'natural-lawson-items',
    meta: { 
      title: 'ナチュラルローソンでコンビニダイエット！カロリー', 
      miniTitle: 'ナチュロー カロリー', 
      desc:'ナチュラルローソン（Natural Lawson）商品の成分表示 弁当やおかず、ホットスナック等 カロリー順、糖質順、タンパク質順、塩分順、食物繊維順、並び替え検索可能。'
    },
    component: () => import(/* webpackChunkName: "tool" */ '../components/pages/tool/NaturalLawsonItems.vue')
  },
  { 
    path: '/service/tool/lawson-items',
    name: 'lawson-items',
    meta: { 
      title: 'ローソンでコンビニダイエット！カロリー', 
      miniTitle: 'ローソン カロリー', 
      desc:'ローソン（Lawson）商品の成分表示 弁当やおかず、ホットスナック等 カロリー順、糖質順、タンパク質順、塩分順、食物繊維順、並び替え検索可能。'
    },
    component: () => import(/* webpackChunkName: "tool" */ '../components/pages/tool/LawsonItems.vue')
  },
  { 
    path: '/service/tool/health-kcal',
    name: 'health-kcal',
    meta: { 
      title: '基礎代謝量と総消費カロリー計算ツール', 
      miniTitle: '基礎代謝・総消費Kcal', 
      desc:'基礎代謝量（BMR）や、総消費カロリー（TDEE）を、様々な計算式でより精密に計算できます。'
    },
    component: () => import(/* webpackChunkName: "tool" */ '../components/pages/tool/HealthKcal.vue')
  },
  //##【order-app】################################################################################################################
  { 
    path: '/service/order',
    name: 'order',
    meta: { 
      title: '受注作成ページ ', 
      miniTitle: '受注作成', 
      desc:'注文があったページを公開しています。'
    },
    component: () => import(/* webpackChunkName: "order" */ '../components/pages/order/OrderPageTop.vue')
  },
  { 
    path: '/service/order/kkj-search-wdg',
    name: 'kkj-search-wdg',
    meta: { 
      title: 'WDG 官公需情報検索ツール', 
      miniTitle: 'WDG官公需情報', 
      desc:'一定の条件で官公需情報APIを使用して検索します。'
    },
    component: () => import(/* webpackChunkName: "order" */ '../components/pages/order/KkjSearchWdg.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
