
<template>

  <div id="app">
      <header-menu class="header-menu" />
      <div class="pageDesc">{{$_getPageDesc()}}</div>
      <div class="contents page-area">
        <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls: uk-animation-slide-bottom-small; target: .main-card ,.sub-card,textarea,.talking; delay: 200; repeat: true">
          <router-view />
        </div>
      </div>  
      <footer-parts class="footer-bar" />
  </div>
</template>

<script>

import HeaderMenu from './components/modules/Header.vue'
import FooterParts from './components/modules/Footer.vue'

export default {
  name: 'App',
  components: {
    HeaderMenu,
    FooterParts,
  },
  data(){
    return {
      show: true,
    }
  },
  watch: {
    // 遷移時 or Reload
    '$route': function (to) {
      this.isShow(to.path);
      this.setDocumentTitle(to);
    }
  },
  methods: {
    // topページの表示切替
    isShow(path) {
      this.show = (path === "/");
    },
    // タイトルを設定
    setDocumentTitle(route){
      const title = route.meta.title
      document.title = (title!=='') ? title + ' – POx2' : 'POx2';
      document.querySelector("meta[property='og:title']").setAttribute('content', document.title)

      // ディスクリプションを設定
      let setDesc =  (route.path === "/") ? this.commonSetting.defaultDesc : ''
      if(route.meta.desc){
        setDesc = route.meta.title;
      }
      document.querySelector("meta[name='description']").setAttribute('content', setDesc)
      document.querySelector("meta[property='og:description']").setAttribute('content', setDesc)
      
    }
  },
  mounted(){
    this.setDocumentTitle(this.$route);
  }
}
</script>



<style lang="scss">


@import url('https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp.min.css');
@import url('https://fonts.googleapis.com/css2?family=Klee+One:wght@400;600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Murecho:wght@100;200;300;600;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaisei+Opti:wght@400;500;700&display=swap');

:root {
  --main-color: #ffc8c8;
  --main-bgcolor: #232323;
  --main-bgcolor2: #000000;
  --sub-color1: #2E4F81;
  --sub-color2: #6D85B1;
  --sub-color3: #A5BECC;

  
  --font-family-text:  'Murecho', "BIZ UDPGothic", Meiryo, sans-serif,cursive;
  // --font-family-text:  YakuHanJP, "SF Pro JP", "SF Pro Text", "SF Pro Icons", "BIZ UDPGothic", Meiryo, sans-serif,cursive;
  --font-family-menu:  'Klee One',sans-serif,cursive;
  --font-family-special: 'Kaisei Opti', serif;

    /* Set neon color */
  --neon-color: var(--main-color);
  font-size: 16px;
  @media screen and (max-width: 800px) {
    font-size: 14px!important;
  }  
}

body {
  font-family: var(--font-family-text);
  font-weight: 400;
  color: #fff !important;
}


h1,h2,h3,h4,h5,h6{
  font-family: var(--font-family-menu) !important;
}
.header-menu{
  position: absolute;
  top:0px;
  left:0px;
  z-index: 2;
  width:100%;
}

.page-area{
  height: 100%;
  background-color: var(--main-bgcolor);
}
.pageDesc{
  position: absolute; top:0; left:0 ;
  font-size: 0.8rem;
}
.contents {
  height: 100%;
  min-height: 90vh;
}
.contents-one {
  height:90vh;
  min-height:90vh;
}
.parallax-bg {
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  min-height: 90vh;
  padding: 2% 4%;
}

.main-card{
  background-color:var(--main-bgcolor2);
  padding:2% 0;
  border-radius: 10px ;

  .sub-card{
    width:92%;
    padding:0 2%;
    margin:2%;
    
  }
  >*a:hover{
    text-decoration: none;
  }
}

.sub-card-top{
  background-size: cover;
  position: relative;
  height: 40vh;
  
  .parent {
    background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1));
    width: 100%;
    height: 70%;
    position: absolute;
    bottom: 0;
    right:  0;

    p.text {
      position: absolute;
      bottom: 0;
      margin: 3%;
      
    }
    .button-area{
      position: absolute;
      right:  0;
      bottom: 0;
      margin: 3%;
      text-align: right;
    }
  }
}

.section-title{
  border: 3px solid rgb(146, 146, 146);
  font-family: var(--font-family-cap);
  
  padding: 1vh 2vw;
  margin:2vh 20vw 2vh 0 ;
  font-size: 1.7rem;
  text-shadow: 2px 2px 2px #272727;
}
.section-main{
  background-color: var(--main-bgcolor);
  
  padding: 2vh 3vw 3vh 3vw;
  font-weight: 400 ;
}

.heading-number {
	position: relative;
  color: #ffffff;
  font-weight: 600;
}

.heading-number::before {
	content: attr(data-number);
	display: inline-block;
	margin-right: 20px;
	color: var(--main-color);
	font-size: 2rem;
	border-bottom: 1px solid  var(--main-color);
}

/* フェードイン・フェードアウト中の設定 */
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
/* フェードイン開始時・フェードアウト終了時の設定 */
.fade-enter, .fade-leave-to {
    opacity: 0;
}


/*== ボタン共通設定 */
.btn{
  position: relative;
  overflow: hidden;
  text-decoration: none;
  display: inline-block;
  border: 1px solid rgb(255, 255, 255);/* ボーダーの色と太さ */
  padding: 2vh 2vw;
  text-align: center;
  font-size: 1.4rem;
  outline: none;
  transition: ease .2s;

    span {
      position: relative;
      z-index: 3;/*z-indexの数値をあげて文字を背景よりも手前に表示*/
      color:#fff;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background:rgba(76, 76, 76, 0.4);/*背景色*/
      width: 100%;
      height: 100%;
      transition: transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
      transform: scale(0, 1);
      transform-origin: right top;
    }

    &:hover:before{
      transform-origin:left top;
      transform:scale(1, 1);
    }
}

.before-frame {
    padding: 1rem;
    border: 2px dotted var(--main-color);
    border-radius: 10px;
    // box-shadow: 0 0 0 5px #333;
    box-shadow: 
    0 0 0 5px var(--sub-color1),
		0px 0px 8px 8px rgba(0, 0, 0, .7);
    background-color: var(--sub-color1);
    // background-color: #333;

}
.after-frame {
    position: relative;
    padding: 1rem;
    border-radius: 5px;
    background-color: var(--sub-color2);
    background-image:
        linear-gradient(var(--sub-color2) 0 100%),
        repeating-linear-gradient(135deg, #fff 0 5px, transparent 5px 10px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: calc(100% - 10px) calc(100% - 10px), 100%;
    color: #fff;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, .7);
}

</style>
