<!-- WorldingのLogoをコネコネする -->
<template>
    <div >
      <router-link to="/">
        <pox2-logo />
      </router-link>
    </div>
</template>

<script>
import Pox2Logo from "@/assets/pox2.svg"

export default {
  components: {
    Pox2Logo,
  },
};
</script>

<style >
#svg26 {
    height:100% !important;
    width: 100%;
}

@keyframes icon-parts {
  0% {transform:  rotate(0deg);}
  33% {transform: translateY(3%) scale(1,1);}
  66% {transform: translateY(0%) scale(1.01, 0.95);}
  100% {transform:  transparent  scale(1.01, 0.80); }
}

.popo {
    /** アニメーション  name | 所要時間 | 減速⇒高速 | 無限に繰り返す */
    -webkit-animation:  icon-parts  0.5s ease-in infinite;
    animation:  icon-parts  0.5s ease-in infinite;
    transform-origin:left bottom;
    filter: drop-shadow(1.5px 1.5px 1px rgb(16, 17, 17)) drop-shadow(1px 1px 4px rgba(255, 255, 255, 0.5));
    
}

@keyframes moji {
  5% {transform: translateY(-5%);}
  10% {transform: translateY(0);}
  40% {stroke-width: 0.1px; stroke: rgba(70, 70, 70, 0.5);}
  60% {transform: translateY(0);fill: #daa9ad;}
  70% {
    fill: rgb(92, 92, 92);
    stroke-dashoffset: 1800;
  }
  90% {
    fill: #eee;
    stroke-dashoffset: 0;
  }
  100% {
    fill: #daa9ad;
    stroke-dashoffset: 0;
  }
}

svg .moji {
    /** アニメーション  name | 所要時間 | 高速⇒減速 | 無限に繰り返す */
  -webkit-animation:  moji 5s ease-in-out infinite;
  animation:  moji 5s ease-in-out infinite;
  transform-origin:left bottom;
}

svg g path.text-p {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
path.text-o {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
path.text-x {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
path.text-2 {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

</style>