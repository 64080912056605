import Vue from 'vue'
import App from './App.vue'
import router from './router'
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import 'uikit/dist/css/uikit.css'
import 'uikit/dist/css/uikit.min.css'
import mixin from '@/mixins'
import axios from 'axios'
import VueAxios from 'vue-axios'
import PageTitle from './components/modules/parts/label/PageTitle.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueGoodTablePlugin from 'vue-good-table';

// import the styles 
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin);

library.add(faCoffee)

Vue.component('font-awesome-icon', FontAwesomeIcon)
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
// ...
library.add(fas, far)

UIkit.use(Icons)
Vue.use(VueAxios, axios)

Vue.config.productionTip = false
Vue.mixin(mixin) 

Vue.component('page-title', PageTitle)
new Vue({
  router,
  mixins: [mixin],
  render: h => h(App)
}).$mount('#app')
